import React from "react"

import Eris from "@images/img-min/partner-eris.svg"
import GreenStreet from "@images/img-min/partner-green-street.svg"
import Morningstar from "@images/img-min/partner-morningstar.svg"
import Kpmg from "@images/img-min/partner-kpmg.svg"
import AvalonBay from "@images/img-min/partner-avalonbay-communities.svg"

export default function Partners() {
  return (
    <section id="partners" className="partners">
      <div className="container">
        <h2>Featured Customers</h2>
        <div className="partners__grid">
          <Eris />
          <GreenStreet />
          <Morningstar />
          <Kpmg />
          <AvalonBay />
        </div>
      </div>
    </section>
  )
}
