import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

const ClimateRisks = ({
  card1Image,
  card2Image,
  card3Image,
  card4Image,
  card5Image,
  card6Image,
  card7Image,
  card8Image,
  card9Image,
  card10Image,
}) => {
  return (
    <section className="climate-risks">
      <div className="container">
        <h2>climate risks</h2>
        <h3>
          ClimateCheck’s predictive hazard models enable real estate
          stakeholders to manage and mitigate climate risk
        </h3>
        <div className="climate-risks__cards">
          <div className="climate-risks__card">
            <Link to="/risks/flood" className="link-img">
              <GatsbyImage
                image={card1Image.childImageSharp.gatsbyImageData}
                alt="Storm and lightning"
              />
            </Link>
            <Link to="/risks/flood" className="link-btn">
              Precipitation
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/drought" className="link-img">
              <GatsbyImage
                image={card2Image.childImageSharp.gatsbyImageData}
                alt="Land with dry and cracked ground"
              />
            </Link>
            <Link to="/risks/drought" className="link-btn">
              Drought
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/heat" className="link-img">
              <GatsbyImage
                image={card3Image.childImageSharp.gatsbyImageData}
                alt="A desert"
              />
            </Link>
            <Link to="/risks/heat" className="link-btn">
              Extreme Heat
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/fire" className="link-img">
              <GatsbyImage
                image={card4Image.childImageSharp.gatsbyImageData}
                alt="Wildfire"
              />
            </Link>
            <Link to="/risks/fire" className="link-btn">
              Wildfire
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks" className="link-img">
              <GatsbyImage
                image={card5Image.childImageSharp.gatsbyImageData}
                alt="Collapsed buildings by earthquake"
              />
            </Link>
            <Link to="/risks" className="link-btn">
              Seismic
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/storm" className="link-img">
              <GatsbyImage
                image={card6Image.childImageSharp.gatsbyImageData}
                alt="A tornado"
              />
            </Link>
            <Link to="/risks/storm" className="link-btn">
              Extreme Wind
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/storm/storm-surge" className="link-img">
              <GatsbyImage
                image={card7Image.childImageSharp.gatsbyImageData}
                alt="Big waves"
              />
            </Link>
            <Link to="/risks/storm/storm-surge" className="link-btn">
              Flood: Storm Surge
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link
              to="/risks/flood/sea-level-rise-risks-for-real-estate"
              className="link-img"
            >
              <GatsbyImage
                image={card8Image.childImageSharp.gatsbyImageData}
                alt="Sea"
              />
            </Link>
            <Link
              to="/risks/flood/sea-level-rise-risks-for-real-estate"
              className="link-btn"
            >
              Flood: Sea Level Rise
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/flood" className="link-img">
              <GatsbyImage
                image={card9Image.childImageSharp.gatsbyImageData}
                alt="A submerged car by floodwaters"
              />
            </Link>
            <Link to="/risks/flood" className="link-btn">
              Flood: Pluvial & Fluvial
              <CTARightArrow />
            </Link>
          </div>
          <div className="climate-risks__card">
            <Link to="/risks/storm" className="link-img">
              <GatsbyImage
                image={card10Image.childImageSharp.gatsbyImageData}
                alt="View of clouds from space"
              />
            </Link>
            <Link to="/risks/storm" className="link-btn">
              Hurricane
              <CTARightArrow />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClimateRisks
